<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-tabs
      v-model="tabIndex"
      content-class="col-12 col-md-12 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-12 col-12"
      nav-class="nav-left"
      class="row document-tab"
    >
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold default">
            {{ $t('dashboard') }}
          </span>
        </template>
        <dashboard />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold default">
            {{ $t('register') }}
          </span>
        </template>
        <register />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold default">
            {{ $t('members') }}
          </span>
        </template>
        <Members />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import { BTabs, BTab, BOverlay } from 'bootstrap-vue'
import Register from './Register.vue'
import Dashboard from './Dashboard.vue'
import tabsMacher from '@/mixins/tabsMacher'
import Members from '@/views/ib-pannel/members/index.vue'

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    Register,
    Dashboard,
    Members,
  },
  mixins: [tabsMacher],
  data() {
    return {
      show: true,
    }
  },

  created() {
    setTimeout(() => {
      this.show = false
    }, 1000)
  },
}
</script>
