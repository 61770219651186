<template>
  <b-row>
    <b-col cols="12">
      <b-card :title="$t('register')">

        <ValidationObserver v-slot="{ invalid }">
          <b-form @submit.prevent="onSubmit">
            <b-row>
              <b-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('capital_amount')"
                  rules="required"
                >
                  <b-form-group
                    id="capitalAmount"
                    :label="$t('capital_amount')"
                    label-for="capitalAmount"
                  >
                    <b-form-select
                      id="capitalAmount"
                      v-model="form.capitalAmount"
                      :options="capitalAmountOptions"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Number_customers_to_introduce')"
                  rules="required"
                >
                  <b-form-group
                    id="Number_customers_to_introduce"
                    :label="$t('Number_customers_to_introduce')"
                    label-for="Number_customers_to_introduce"
                  >
                    <b-form-select
                      id="Number_customers_to_introduce"
                      v-model="form.customerCount"
                      :options="customerCountOptions"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('start_hour')"
                  rules="required"
                >
                  <b-form-group
                    id="start_hour"
                    :label="$t('start_hour')"
                    label-for="start_hour"
                  >
                    <date-picker
                      id="start_hour"
                      v-model="form.startHour"
                      color="green"
                      :locale="$i18n.locale"
                      :max="form.endHour"
                      type="time"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('end_hour')"
                  rules="required"
                >
                  <b-form-group
                    id="end_hour"
                    :label="$t('end_hour')"
                    label-for="end_hour"
                  >
                    <date-picker
                      id="end_hour"
                      v-model="form.endHour"
                      color="red"
                      :locale="$i18n.locale"
                      :min="form.startHour"
                      type="time"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('trading_type')"
                  rules="required"
                >
                  <b-form-group
                    id="trading_type"
                    :label="$t('trading_type')"
                    label-for="trading_type"
                  >
                    <b-form-select
                      id="trading_type"
                      v-model="form.tradingType"
                      :options="tradingTypeOptions"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="my-1">
                <b-form-group
                  id="checkbox"
                  label-for="checkbox"
                >
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="form.terms"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >
                    <span
                      style="text-decoration: underline; cursor:pointer"
                      @click.prevent="termsModal = true"
                    >
                      {{ $t('terms_and_conditions') }}
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="auto">
                <b-button
                  type="submit"
                  variant="primary"
                  class="flex-end"
                  :disabled="invalid || form.terms !== 'accepted'"
                >
                  <span v-if="!isLoading">
                    {{ $t("register") }}</span>
                  <b-spinner
                    v-else
                    size="10"
                    variant="light"
                    type="grow"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>

    <b-col>
      <b-row>
        <b-col
          v-for="item in userCards"
          :key="item.id"
          cols="auto"
        >
          <b-alert
            :variant="item.status === 'pending'? 'warning' : item.status === 'success' ? 'success' : 'danger' "
            show
            style="width:400px"
            class="px-1"
          >
            <h4 class="alert-heading">
              {{ $t(item.statusMessage) }}
              <feather-icon
                :icon="item.icon"
                class="mr-75"
              />
            </h4>
            <div class="alert-body">
              <div style="margin:5px 0">
                <span>{{ $t('capital_amount') }}</span>
                <span> : </span>
                <span>{{ item.capitalAmount }}</span>
              </div>
              <div style="margin:5px 0">
                <span>{{ $t('working_hours') }}</span>
                <span> : </span>
                <span>{{ item.workingHours }}</span>
              </div>
              <div style="margin:5px 0">
                <span>{{ $t('Number_customers_to_introduce') }}</span>
                <span> : </span>
                <span>{{ item.customerNumber }}</span>
              </div>
              <div style="margin:5px 0">
                <span>{{ $t('trading_type') }}</span>
                <span> : </span>
                <span>{{ item.tradingType }}</span>
              </div>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-col>

    <!-- modal -->
    <b-modal
      v-model="termsModal"
      :title="$t('terms_and_conditions')"
      size="lg"
      ok-only
    >
      <div
        v-for="(item,index) in termsItems"
        :key="index"
        class="terms_item"
      >
        <span>
          <feather-icon
            icon="CheckIcon"
            size="12"
          />
        </span>
        <span>
          {{ item }}
        </span>
      </div>

      <template #modal-footer>
        <button
          class="btn btn-success btn-sm m-1"
          @click="()=>{
            termsModal =false
            form.terms = 'accepted'
          }"
        >
          {{ $t('ok') }}
        </button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BSpinner,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import DatePicker from 'vue-persian-datetime-picker'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    BFormCheckbox,
    BModal,
    BFormSelect,
    BAlert,
    DatePicker,
  },

  data() {
    return {
      termsModal: false,
      isLoading: false,
      form: {
        capitalAmount: '',
        customerNumber: '',
        workingHours: '',
        startHour: '',
        endHour: '',
        tradingType: '',
        terms: 'accepted',
      },
      required,
      termsItems: [
        'this is a test',
        'this is a test',
        'this is a test',
        'this is a test',
        'this is a test',
        'this is a test',
      ],
      customerCountOptions: [
        { value: 1, text: '1-10' },
        { value: 2, text: '1-20' },
        { value: 3, text: '1-30' },
        { value: 4, text: '1-40' },
        { value: 5, text: '1-50' },
      ],
      capitalAmountOptions: [
        { value: 1, text: '1-1000000' },
        { value: 2, text: '1-2000000' },
        { value: 3, text: '1-3000000' },
        { value: 4, text: '1-4000000' },
        { value: 5, text: '1-5000000' },
      ],
      tradingTypeOptions: [
        { value: 1, text: 'روزانه' },
        { value: 2, text: 'نوسانی' },
        { value: 3, text: 'بلند مدت' },
        { value: 4, text: 'اسکالپر' },
      ],

      userCards: [
        {
          id: 1,
          capitalAmount: '1-10000',
          customerNumber: '1-20',
          workingHours: '10',
          test: 'amir1',
          icon: 'LoaderIcon',
          tradingType: 'بلند مدت',
          status: 'pending',
          statusMessage: this.$t('pending'),
        },
        {
          id: 2,
          capitalAmount: '1-10000',
          customerNumber: '1-20',
          workingHours: '10',
          test: 'amir2',
          icon: 'CheckSquareIcon',
          tradingType: 'بلند مدت',
          status: 'success',
          statusMessage: this.$t('success'),
        },
        {
          id: 3,
          capitalAmount: '1-10000',
          customerNumber: '1-20',
          workingHours: '10',
          test: 'amir3',
          icon: 'XIcon',
          tradingType: 'بلند مدت',
          status: 'warning',
          statusMessage: this.$t('warning'),
        },
      ],
    }
  },

  methods: {
    onSubmit() {
      console.log(this.form)
    },
  },
}
</script>

<style>
  .terms_item{
    margin: 6px 0;
  }

  .modal-header .close {
  display:none;
}
</style>
