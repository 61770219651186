<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          lg="3"
          sm="12"
        >

          <statistic-card-with-line-chart
            class="my-3"
            icon="UserIcon"
            statistic="Total referal"
            statistic-title="50"
            style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;"
            :chart-data="[{
              name: 'series-1',
              data: [30, 40, 45, 50, 49, 60, 70, 91]
            }]"
          />
        </b-col>
        <b-col
          lg="3"
          sm="12"
        >
          <statistic-card-with-line-chart
            class="my-3"
            icon="CpuIcon"
            statistic="totoal commission"
            statistic-title="32000"
            style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;"
            :chart-data="[{
              name: 'series-1',
              data: [30, 40, 45, 50, 49, 60, 70, 91]
            }]"
          />
        </b-col>
        <b-col
          lg="3"
          sm="12"
        >

          <DoughnutChart class="my-3" />
        </b-col>
        <b-col
          lg="3"
          sm="12"
        >
          <DoughnutChart class="my-3" />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h3 class="font-weight-bold">
        New Member
      </h3>
      <LineChart
        :chart-data="chartData"
        class="mb-5"
      />

      <h3 class="font-weight-bold">
        Commisons
      </h3>
      <LineChart
        class="mb-5"
        :chart-data="chartData"
      />

      <h3 class="font-weight-bold">
        Member Trades
      </h3>
      <LineChart :chart-data="chartData" />
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import statisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import LineChart from '@/views/ib-pannel/LineChart.vue'
import DoughnutChart from '@/views/ib-pannel/DoughnutChart.vue'

export default {
  components: {
    BCard,
    LineChart,
    DoughnutChart,
    statisticCardWithLineChart,
    BRow,
    BCol,
  },
  data() {
    return {
      loading: false,
      chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
        ],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 39, 10, 40, 39, 80, 40],
          },
        ],
      },
    }
  },
  mounted() {
    this.loading = true
  },
}
</script>
